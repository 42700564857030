<template>
    <div class="swiper-container homebanner_swiper">
        <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in bannerdata" :key="index">
                <a class="imgbox" v-if="item.LinkUrl" @click.prevent="tolink(item.LinkUrl)" target="_blank"
                    :style="{ background: 'url(' + item.AdImageFileUrl + ') no-repeat center center' }"></a>
                <div class="imgbox" v-else
                    :style="{ background: 'url(' + item.AdImageFileUrl + ') no-repeat center center' }"></div>
            </div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="form_box">
            <div class="fbox">
                <div class="tbtitle">{{ query.Year }}志愿模拟填报<span class="tips-province">{{ query.ProvinceName }}</span></div>
                <div class="fline">
                    <div class="tit">{{ isRealScore ? '高考分数' : '预估分数' }}</div>
                    <div class="input">
                        <input type="text" v-model="examscore" onkeyup="this.value=this.value.replace(/\D/g,'')"
                            maxlength="3" :disabled="(isRealScore&&disableScore)?true:false" :placeholder="`${ProvinceObj.ScoreLow||'请输入分数'}-${ProvinceObj.ScoreHight||''}`" />
                        <span>分</span>
                    </div>
                    <div class="clear"></div>
                </div>
                <div class="fline">
                    <!-- <div class="tit">选考科目</div>
                    <div class="subject">
                        <el-checkbox @change="changecheckbox(item)" v-model="item.checked" :disabled="item.disabled" v-for="(item,index) in subjectdata" :key="index">{{item.name}}</el-checkbox>
                    </div>
                    <div class="clear"></div> -->

                    <div class="box">
                        <div class="item" v-for="(item, index) in selectSubject" :key="index">
                            <div class="title">{{ item.SelectExamTitle || '选考科目' }}</div>
                            <el-checkbox-group class="item-box" v-model="item.selected" :max="item.MaxSelectCount">
                                <el-checkbox class="inner-text" v-for="(subItem) in item.SelectExamSubjects" :key="subItem"
                                    :label="subItem">{{ subItem }}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
                <div class="fline inline">
                    <div class="btn l" @click.prevent="submitbtn(1)" v-if="ProductCode_WDF">{{ ProductCount == 1 ? '' :
                        WDFName
                    }}模拟填报</div>
                    <div class="btn r" @click.prevent="submitbtn(0)" v-if="ProductCode_WCF">{{ ProductCount == 1 ? '' :
                        WCFName
                    }}模拟填报</div>
                    <div class="clear"></div>
                </div>
                <div class="illustrate">
                    <span class="txt" @click.prevent="$router.push('/contrast')"
                        v-if="ProductCode_WDF && ProductCode_WCF">查看{{ WDFName }}与{{ WCFName }}的分析说明</span>
                </div>
                <div class="tipsbox" :class="tipsshowstyle"><span><i class="iconfont">&#xe64d;</i></span>{{ tipsshowtxt }}
                    <div class="clear"></div>
                </div>
            </div>
            <div class="clear"></div>
        </div>
        <!-- <batch ref="selectBatch" @selectInfo="selectInfo" :wishCategory="wishCategory" :Score="query.Score"
            :SubjectType="query.SubjectType" :ProvinceId="query.ProvinceId" :ProvinceName="query.ProvinceName"></batch>
        <new-batch ref="newBatch" :ProvinceId="query.ProvinceId" :ProvinceName="query.ProvinceName"
            :ExamSubject="query.ExamSubject" :Score="query.Score" :Year="query.Year" :wishType="wishCategory"
            @batchChange="batchChange" v-on="$listeners"></new-batch> -->
    </div>
</template>
<script>
// 引入Swiper
import Swiper from 'swiper/js/swiper';
import "swiper/css/swiper.css";

import Batch from '@/views/choose-wish/local-components/BatchDialog'
import NewBatch from '@/views/newentrance/bachlist'
import { getStore } from "@/utils/utils";
import API from "@/api/config";
import { Message } from 'element-ui';
export default {
    components: {
        Batch,
        NewBatch
    },
    name: 'Banner',
    data() {
        return {
            //轮播
            bannerdata: [],
            //选科
            subjectdata: [
                { name: '物理', checked: false, disabled: false, valule: 'physics' },
                { name: '化学', checked: false, disabled: false, valule: 'chemistry' },
                { name: '生物', checked: false, disabled: false, valule: 'organism' },
                { name: '历史', checked: false, disabled: false, valule: 'history' },
                { name: '政治', checked: false, disabled: false, valule: 'politics' },
                { name: '地理', checked: false, disabled: false, valule: 'geography' }
            ],
            examscore: '',
            checksubject: [],
            tipsshow: false,
            tipsshowstyle: '',
            tipsshowtxt: '',

            wishCategory: 0, // 1位点 0位次

            isNewExam: true,
            selectSubject: [],

            ProductCode_WDF: false,
            ProductCode_WCF: false,
            ProductCount: 0,
            WDFName: '',
            WCFName: '',
            ProductCodes: [],
            ProductAlias: {},
            query: {
                Score: 0,
                SubjectType: null,
                ProvinceId: null,
                ProvinceName: '',
                Year: '',
                // ExamSubject: []
            },

            ProvinceObj: {},

            isRealScore: false,
            disableScore: false
        }
    },
    mounted() {
        this.initialize();
        this.GetAdList();

    },
    methods: {

        tolink(url) {
            if (url != null && url != '') {
                if (url == '/') {
                    this.$router.push({ path: '/develop' });
                }
                else {
                    // 处理参数
                    if(url.indexOf('?')>-1){
                        //获取用户登录信息
                        let user = getStore("LoginData");
                        if (user != undefined && user != null && user != "") {
                            user = JSON.parse(user);
                        }
                        else{
                            if(url.indexOf('{userid}')>-1||url.indexOf('{token}')>-1||url.indexOf('{nickname}')>-1){
                                Message({ type: 'error', message: "请先登录！" });
                                this.$router.push({ path: '/login' });
                                return false;
                            }
                        }
                        if(url.indexOf('{userid}')>-1){
                            url = url.replace('{userid}',user.UserId);
                        }
                        if(url.indexOf('{token}')>-1){
                            url = url.replace('{token}',user.Token);
                        }
                        if(url.indexOf('{nickname}')>-1){
                            url = url.replace('{nickname}',user.NickName);
                        }
                    }
                    if (url.indexOf('http') != -1) {
                        window.open(url);
                    }
                    else {
                        this.$router.push({ path: url });
                    }
                }
            }
        },
        initialize() {
            
            // 省份
            let pdata = getStore('OrgProvince');
            if (pdata != null) {
                this.query.ProvinceId = JSON.parse(getStore('OrgProvince')).ProvinceId
                this.query.ProvinceName = JSON.parse(getStore('OrgProvince')).ProvinceName

                this.ProvinceObj = JSON.parse(getStore('OrgProvince'))
            }

            //获取产品
            let pcode = getStore('ProductCodes');
            if (pcode != undefined) {
                this.ProductCodes = JSON.parse(pcode);
                let palias = getStore('ProductAlias');
                if (palias != null) {
                    this.ProductAlias = JSON.parse(palias);
                }
                let wdf = this.ProductCodes.find(f => f === 'WDF');
                
                if (this.ProvinceObj.EnableWD && wdf) {
                    this.ProductCode_WDF = true;
                    this.WDFName = this.ProductAlias.WDF;
                    this.ProductCount += 1;
                }
                let wcf = this.ProductCodes.find(f => f === 'WCF');
                
                if (this.ProvinceObj.EnableWC && wcf) {
                    this.ProductCode_WCF = true;
                    this.WCFName = this.ProductAlias.WCF;
                    this.ProductCount += 1;
                }
            }

            this.$getProvinceIsFinalScore(this.query.ProvinceId, isRealScore => {
                this.isRealScore = isRealScore

                this.getYear().then(() => {
                    this.$store.dispatch('user/judgeLoginStatus').then(flag => {
                        if (flag) { // 已登录
                            this.$store.dispatch('user/getStudentInfo').then(userInfo => {
                                this.studentInfo = userInfo
                                if (userInfo.UserScore) {

                                    if (this.query.ProvinceId === userInfo.UserScore.ExamProvinceId) { // 省份未改变

                                        this.handleExamType().then(() => {
                                            this.query.Score = (isRealScore ? userInfo.UserScore.FinalScore : userInfo.UserScore.Score)
                                            this.examscore = (isRealScore ? userInfo.UserScore.FinalScore : userInfo.UserScore.Score)
                                            this.disableScore = (userInfo.UserScore.FinalScore && userInfo.UserScore.FinalScore !== 0)?true:false

                                            // 已选中科目绑定
                                            this.handleHadInfo(userInfo)
                                       
                                        })

                                    } else {
                                        this.handleExamType()

                                        this.handleHadInfo()
                                    }
                                } else {
                                    this.handleExamType()

                                    this.handleHadInfo()
                                }
                            })
                        } else { // 未登录
                            this.handleExamType()

                            this.handleHadInfo()
                        }
                    })
                })
            })
        },
        handleHadInfo(userInfo) {

            // if (!userInfo) {
            //     userInfo = {}
            //     let tempUser = getStore('tempScoreInfo')
            //     if (tempUser) {
            //         tempUser = JSON.parse(tempUser)
            //     } else {
            //         tempUser = {}
            //     }
            //     userInfo.UserScore = tempUser
            //     this.examscore = userInfo.UserScore.Score
            // }

                userInfo = userInfo || {}
                let tempUser = getStore('tempScoreInfo')
                if (tempUser) {
                    tempUser = JSON.parse(tempUser)

                    userInfo.UserScore = tempUser
                    this.examscore = userInfo.UserScore.Score
                } else {
                    tempUser = {}
                }
                
            
            // 已选中科目绑定
            // 判定省份选科类型
            
            if (this.ProvinceObj.EnableSubjectType) { // 开启首选
                
                if (userInfo.UserScore) {
                    if (userInfo.UserScore.SubjectType) {
                        if (userInfo.UserScore.SubjectType==1) {
                            this.selectSubject[0].selected = ['物理']
                        } else if (userInfo.UserScore.SubjectType==2) {
                            this.selectSubject[0].selected = ['历史']
                        } else {
                            this.selectSubject[0].selected = []
                        }
                        if (userInfo.UserScore.SubjectList) {

                            this.selectSubject[1].selected = userInfo.UserScore.SubjectList
                        } else {
                            this.selectSubject[1].selected = []
                        }
                    } else {
                        this.selectSubject[1].selected = userInfo.UserScore.SubjectList || []
                    }
                }
                
            } else {
                
                if (userInfo.UserScore.SubjectList) {
                    this.selectSubject[0].selected = userInfo.UserScore.SubjectList
                } else {
                    this.selectSubject[0].selected = []
                }
            }

        },
        // 年份
        getYear() {
            // 年份
            return new Promise(resolve => {
                this.$store.dispatch('tool/getSystemYear').then(year => {
                    this.query.Year = year
                    resolve()
                })
            })
        },
        // 新老高考判断
        handleExamType() {
            // 判断
            return new Promise(resolve => {

                if (this.ProvinceObj.EnableSubjectType) { //  存在物理历史
                    this.selectSubject = [
                        {
                            selected: [],
                            MaxSelectCount: 1,
                            SelectExamTitle: "首选",
                            SelectExamSubjects: [
                                '物理',
                                '历史'
                            ]
                        },
                        {
                            selected: [],
                            MaxSelectCount: this.ProvinceObj.SubjectOptionsCount,
                            SelectExamTitle: "再选",
                            SelectExamSubjects: this.ProvinceObj.SubjectOptions.split(',')
                        }
                    ]

                } else { // 不存在物理历史
                    this.selectSubject = [
                        
                        {
                            selected: [],
                            MaxSelectCount: this.ProvinceObj.SubjectOptionsCount,
                            SelectExamSubjects: this.ProvinceObj.SubjectOptions.split(',')
                        }
                    ]
                }
                resolve()
                

                // this.$store.dispatch("user/judgeNewExam", {
                //     id: this.query.ProvinceId,
                //     year: this.query.Year,
                // }).then(res => {
                //     this.isNewExam = res.IsNewExam
                //     if (res.IsNewExam) {
                //         this.selectSubject = res.RuleList.map(item => {
                //             item.selected = []

                //             return item
                //         })

                //     } else {
                //         this.selectSubject = [
                //             {
                //                 selected: [],
                //                 MaxSelectCount: 1,
                //                 SelectExamSubjects: [
                //                     '文科',
                //                     '理科'
                //                 ]
                //             }
                //         ]
                //     }
                //     resolve()
                // })
            })
        },

        GetAdList() {
            API.Web.GetAdList({ AdPositionId: 1 }).then(res => {
                if (res.Code === 0) {
                    this.bannerdata = res.Data.Data;
                    this.$nextTick(() => {
                        new Swiper(".homebanner_swiper", {
                            speed: 1000,//切换速度
                            loop: true,//开启循环
                            autoplay: {
                                delay: 3000,//循环间隔时间
                                disableOnInteraction: false,//操作分页切换或手动滑动后继续自动切换
                            },
                            effect: 'fade',
                            pagination: {
                                clickable: true,//允许点击分页切换
                                el: ".swiper-pagination"
                            }
                        });
                    });
                }
            });
        },

        batchChange(parameters) {

            this.$router.push('/new-exam')

        },

        // 选择批次
        selectInfo(parameters) {
            this.$router.push('/choose-wish')

        },

        //科目选择/切换
        changecheckbox(item) {
            if (item.checked) {
                this.checksubject.push(item);
                if (this.checksubject.length == 3) {
                    this.subjectdata.forEach(it => {
                        if (it.checked === false) {
                            it.disabled = true;
                        }
                    });
                }
            }
            else {
                this.checksubject = this.checksubject.filter(ch => ch.valule !== item.valule);
                this.subjectdata.forEach(it => {
                    it.disabled = false;
                });
            }
        },
        //填报按钮
        submitbtn(t) {

            // 登录状态校验
            this.$store.dispatch('user/judgeLoginStatus').then(flag => {
                if (!flag) { // 未登录
                    this.$message.info('请先登录！')
                    setTimeout(() => {
                        this.$router.push('/login')
                    }, 300)
                } else { // 已经登录
                    // 验证考试信息

                    this.$store.dispatch('user/judgeInfoComplete').then(() => {

                        // 正常流程---------------------------
                        // 分数
                        if (!this.examscore) {
                            this.verifytips("请输入预估分数"); return false;
                        }
                        if (this.ProvinceObj.ScoreHight<this.examscore||this.ProvinceObj.ScoreLow>this.examscore) {
                            this.verifytips("超出可推荐的分数范围"); return false;
                        }
                        this.query.Score = this.examscore * 1

                        // 科目
                        let flag = this.selectSubject.some(item => item.selected.length !== item.MaxSelectCount)

                        if (flag) {
                            this.verifytips("请完善科目选择"); return false;
                        }

                        // type
                        this.wishCategory = (t === 1 ? 1 : 0) // 1

                        // ProvinceId: "16544791295722512",
                        // ProvinceName: "河南",
                        // Score: 566,
                        // SubjectType: 1,
                        // Rank: 45898,
                        // Year: 2025,
                        // wishCategory: 1

                        // console.log(this.query, 'this.query')
                        // console.log(this.ProvinceObj, 'this.ProvinceObj')

                        if (this.ProvinceObj.EnableSubjectType) {
                            if (this.selectSubject[0].selected[0]=='物理') {
                                this.query.SubjectType = 1
                            } else if(this.selectSubject[0].selected[0]=='历史') {
                                this.query.SubjectType = 2
                            } else{
                                this.query.SubjectType = null
                            }

                            this.query.SubjectList = this.selectSubject[1].selected

                        } else {
                            this.query.SubjectType = null
                            this.query.SubjectList = this.selectSubject[0].selected
                        }

                        // this.query.ExamSubject = []
                        // this.selectSubject.map(item => {
                        //     this.query.ExamSubject.push({
                        //         SelectExamNum: item.SelectExamNum,
                        //         SelectExamSubject: item.selected,
                        //         SelectExamTitle: item.SelectExamTitle
                        //     })
                        // })
                        setTimeout(() => {
                            // new change----------
                            // 存参
                            this.$setStorage('studentReport', {...this.query, wishCategory: this.wishCategory})
                            this.$setStorage('tempScoreInfo', {...this.query, wishCategory: this.wishCategory})
                            // new change----------

                            // this.$refs.newBatch.show()
                            this.$router.push("/recommend-start")
                        }, 0)
                        
                        // subject
                        // if (this.isNewExam) {
                        //     this.query.ExamSubject = []
                        //     this.selectSubject.map(item => {
                        //         this.query.ExamSubject.push({
                        //             SelectExamNum: item.SelectExamNum,
                        //             SelectExamSubject: item.selected,
                        //             SelectExamTitle: item.SelectExamTitle
                        //         })
                        //     })
                        //     setTimeout(() => {

                        //         // new change----------
                        //         // 存参
                        //         this.$setStorage('studentReport', {...this.query, wishCategory: this.wishCategory})
                        //         // new change----------

                        //         this.$refs.newBatch.show()
                        //     }, 0)
                            
                        // } else {

                        //     this.query.SubjectType = (this.selectSubject[0].selected[0] === '文科' ? 1 : 2)

                        //     setTimeout(() => {
                        //         this.$refs.selectBatch.show()
                        //     }, 0)
                        // }
                    })
                }
            })
        },
        //填报按钮验证提示
        verifytips(txt) {
            this.tipsshow = true;
            this.tipsshowtxt = txt;
            this.tipsshowstyle = "ani_show";
            let st = setTimeout(() => {
                this.tipsshowtxt = "";
                this.tipsshowstyle = "";
                this.tipsshow = false;
                clearTimeout(st);
            }, 1500);
        },
    }
}
</script>
<style lang="less" scoped>
.swiper-container.homebanner_swiper {
    width: 100%;
    height: 360px;
    overflow: hidden;

    .swiper-wrapper {
        height: 100%;

        .imgbox {
            width: 100%;
            height: 100%;
            overflow: hidden;
            display: block;
        }
    }

    .form_box {
        max-width: 1420px;
        height: 100%;
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        margin: 0 auto;

        .fbox {
            width: 350px;
            height: 100%;
            float: right;
            margin-right: 20px;
            position: relative;
            z-index: 1;
            background-color: #fff;

            .tbtitle {
                color: #555;
                font-size: 20px;
                margin-top: 30px;
                margin: 30px 35px 0 31px;
                font-weight: bold;
                padding-bottom: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .tips-province {
                    font-size: 16px;
                    font-weight: 500;
                }
            }

            .fline {
                margin-top: 10px;

                .tit {
                    float: left;
                    color: #666;
                    font-size: 14px;
                    line-height: 42px;
                    margin-right: 10px;
                    margin-left: 31px;
                }

                .input {
                    float: left;
                    width: 213px;
                    height: 40px;
                    border: 1px solid #ddd;

                    input {
                        width: 165px;
                        height: 38px;
                        line-height: 38px;
                        line-height: 1;
                        text-indent: 10px;
                        color: #666;
                        font-size: 14px;
                        border: none;
                    }

                    span {
                        color: #888;
                        font-size: 14px;
                        line-height: 42px;
                        margin-left: 10px;
                    }
                }

                .subject {
                    float: left;
                    width: 246px;

                    .el-checkbox {
                        margin-top: 11px;
                    }
                }

                .btn {
                    width: calc(50% - 35px);
                    height: 40px;
                    text-align: center;
                    line-height: 40px;
                    color: #fff;
                    font-size: 14px;
                    margin: 20px 10px 0px;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                    display: inline-block;
                }

                .btn:hover {
                    cursor: pointer;
                    opacity: 0.85;
                }

                .l {
                    background-color: #f89f32;
                }

                .r {
                    background-color: #fd5d5d;
                }

                .box {
                    .item {
                        display: flex;

                        .title {
                            min-width: 56px;
                            color: #666;
                            font-size: 14px;
                            line-height: 42px;
                            margin-right: 10px;
                            margin-left: 31px;
                        }

                        .item-box {
                            width: 246px;

                            .el-checkbox {
                                margin-top: 11px;
                            }
                        }

                    }

                }
            }

            .fline.inline {
                text-align: center;
            }

            .illustrate {
                text-align: center;
                margin-top: 20px;

                .txt {
                    text-decoration: underline;
                    color: #777;
                    font-size: 14px;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                }

                .txt:hover {
                    cursor: pointer;
                    color: rgb(var(--themecolor));
                }
            }

            .tipsbox {
                position: absolute;
                width: calc(100% - 22px);
                height: 44px;
                line-height: 44px;
                color: #F56C6C;
                text-indent: 10px;
                font-size: 14px;
                top: 0px;
                left: 10px;
                z-index: 2;
                opacity: 0;
                background-color: #fef0f0;
                border: 1px solid #fde2e2;
                border-radius: 5px;

                span {
                    width: 12px;
                    height: 12px;
                    display: block;
                    float: left;
                    margin-left: 15px;
                    margin-top: 16.5px;
                    background-color: #F56C6C;
                    line-height: 5.5px;
                    text-indent: 3.5px;
                    border-radius: 10px;

                    i {
                        font-size: 6px;
                        color: #fff;
                    }
                }
            }

            .tipsbox.ani_show {
                animation: ani_show 1.5s ease-out 0s 1;
                -webkit-animation: ani_show 1.5s ease-out 0s 1;
                animation-fill-mode: forwards;
                -webkit-animation-fill-mode: forwards;

                @-webkit-keyframes ani_show {
                    0% {
                        top: 0px;
                        opacity: 0;
                    }

                    10% {
                        top: 120px;
                        opacity: 1;
                    }

                    90% {
                        top: 120px;
                        opacity: 1;
                    }

                    100% {
                        top: 0px;
                        opacity: 0;
                    }
                }

                @keyframes ani_show {
                    0% {
                        top: 0px;
                        opacity: 0;
                    }

                    10% {
                        top: 120px;
                        opacity: 1;
                    }

                    90% {
                        top: 120px;
                        opacity: 1;
                    }

                    100% {
                        top: 0px;
                        opacity: 0;
                    }
                }
            }
        }
    }
}
</style>
<style lang="less">
.swiper-container.homebanner_swiper .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: #fff;
    opacity: 0.4;
    margin: 0px 8px;
    transition: width 0.3s;
    -webkit-transition: width 0.3s;
}

.swiper-container.homebanner_swiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #fff;
    opacity: 0.8;
    width: 24px;
}

.swiper-container.homebanner_swiper .swiper-pagination.swiper-pagination-bullets {
    width: max-content !important;
    margin: auto;
    display: block;
    position: relative;
    bottom: 40px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 5px 10px;
    border-radius: 20px;
}

.form_box .fbox .fline .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: rgb(var(--themecolor));
    border-color: rgb(var(--themecolor));
}

.form_box .fbox .fline .el-checkbox__input.is-checked+.el-checkbox__label {
    color: rgb(var(--themecolor));
}
</style>